import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import ContactForm from "../components/SharedTailwind/ContactForm";

function PartnersPage(props) {
  return (
    <React.Fragment>
      <Helmet title="Become a Partner - Gatsby Storefront">
        <meta
          name="description"
          content="Become a Partner of Gatsby Storefront for Shopify"
        />
      </Helmet>
      <ContactForm
        title="Become a Gatsby Storefront partner"
        description="Please describe your idea, and we will come back to you shortly."
      />
    </React.Fragment>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default PartnersPage;
